/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Narendra Polasam",
  title: "Hi all, I'm Naren",
  subTitle: emoji(
    "A passionate Full Stack Lead Developer 🚀 having an experience of building Micro Frontend and Services and developing Web and Mobile applications ."
  ),
  resumeLink:
    "https://drive.google.com/file/d/12l42JR1A-AJXFpOFfprJbNNOoFHBN7cl/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/narendrapolasamn",
  linkedin: "https://www.linkedin.com/in/narendra-polasam-4157236b/",
  gmail: "narendra.polasamn@gmail.com",
  gitlab: "https://github.com/narendrapolasamn",
  facebook: "",
  medium: "",
  stackoverflow: "https://stackoverflow.com/users/10133333/naren",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CURRENTLY WORKING AS A Full Stack Lead Developer",
  skills: [
    emoji(
      "⚡ Developing interactive Micro-Frontend web and mobile applications"
    ),
    emoji(
      "⚡ Currentely exploring Azure and power platform solutions"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Currentely using Google Cloud and firebase deploying web and mobile applications"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "Angular",
      fontAwesomeClassname: "fab  fa-angular"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "postgressql",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "google-cloud",
      fontAwesomeClassname: "fa-solid fa-cloud"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
 
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Acharya Nagarjuna University",
      logo: require("./assets/images/Acharya_Nagarjuna_University_crest.png"),
      subHeader: "Bachelor of Maths and Science",
      duration: "June 2003 - March 2006",
      descBullets: [
         "Learn computer programming and implemented small projects after the bachelor degree"
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Lead FullStack Developer",
      company: "Solocal",
      companylogo: require("./assets/images/yelster.jpg"),
      date: "FEB 2021 – Present",
      desc: "I work on web and mobile projects",
      descBullets: [
        "Developing digital transformation project",
        "Using Angular and Ionic, typescript for web and mobile applications",
        "Using Asp.net core rest api, entity framework and Python",
        "Creating Micro Frontend Poc(Angular and react) to intergate with in the organation",
        "Using jira, confluence, git and gitlab for continous deployment and implementaion",
        "Involving in requirement with Product owner",
        "Participated in the discussion and implementation of system design",
        "Proposed usage of industry standards for software development"
      ]
    },
    {
      role: "Module Lead Developer",
      company: "IAEA",
      companylogo: require("./assets/images/Iaea.png"),
      date: "March 2017 – Jan 2021",
      desc: "The project named SIRIUS is responsible to develop Interanet portal and document management system",
      descBullets: [
        "Developed project access for the Senior insepectors daily activities",
        "Identified and delegated work to junior subordinates to work",
        "Having Sharepoint for document metadata and workflow approval process",
        "Created Asp.Net Core rest api services, entity framework to integrate with differnt application with in network",
        "Create Frontend using Angular4 to Angular8 and typescript",
        "Used Swagger-Codegen to auto-build services and models for Angular 8",
        "Used CI/CD with GIT and TFS integrated build and release management tooling"
      ]
    },
    {
      role: "Senior Software Engineer",
      company: "Trigent Software",
      companylogo: require("./assets/images/Trigent.png"),
      date: "Nov 2015 – Jan 2017",
      descBullets: [
        "Developed Web applications and SharePoint Applications",
        "Create Sharepoint application for the USA Clients for document management system",
        "Created entity framework, Asp.Net web api services for the application",
        "Create Frontend using Angular2 and typescript",
        "Used Swagger for web api",
        "Used CI/CD with GIT and TFS integrated build and release management tooling",
      ]
    },
    {
      role: "Senior Consultant",
      company: "Ciber Sites(NOW HTC)",
      companylogo: require("./assets/images/ciber.jpg"),
      date: "Sep 2014 - Sep 2015",
      descBullets: [
        "Developed  Web applications and SharePoint Applications",
        "Migrated Lotus notes application to sharepoint and Asp.net web applications",
        "Created entity framework, Asp.Net web api services for the application",
        "Create Frontend Javascript, Jquey, bootstrap, HTML and css",
        "Involved Architure and design applications",
        "Used Swagger for web api",
        "Used CI/CD with GIT and TFS integrated build and release management tooling",
      ]
    },
    {
      role: "Senior Software Engineer",
      company: "Photon InfoTech",
      companylogo: require("./assets/images/Photon.jpg"),
      date: "Oct 2010 – Aug 2014",
      descBullets: [
        "Developed SharePoint Applications for Brand innovation portal",
        "Created  web application for Estee Lauder",
        "Used entity framework, Asp.Net MVC and web api services for the application",
        "Create Frontend Javascript, Jquey, bootstrap, HTML and css",
        "Involved Architure and design applications",
        "Used Swagger for web api",
        "Used CI/CD with GIT and TFS integrated build and release management tooling",
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/saayaHealthLogo.webp"),
      projectName: "Saayahealth",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://saayahealth.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/nextuLogo.webp"),
      projectName: "Nextu",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://nextu.se/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "",
      subtitle:
        "Kubernetes.",
      image: require("./assets/images/kubernetes.png"),
      imageAlt: "",
      footerLink: [
        {
          name: "Certification",
          url: "https://verify.acloud.guru/57E5327D5DF3"
        },
      ]
    },
 
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt. At the moment the talks are in progress...",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    
  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅, At the moment the talks are in progress..."
  ),

  talks: [
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+4368860085989",
  email_address: "narendra.polasamn@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
